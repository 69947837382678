.white-bg {
    background: $white;
}

.top-heading {


    .nav-pills{
        padding-top: 25px;
    }
}
.hading-tab-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-add-incentive{
&.btn-line {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-left: 10px;

    i {
        font-size: 20px;
        margin-right: 5px;
    }
}
}
.basic-filter-outer{
    h2{
        font-weight: 500;
font-size: 16px;
line-height: 30px;
color: $heading-color;
padding: 0px 0 10px;
    }
}

.tab-line {
    padding-bottom: 10px;

    .tab-list {
        display: flex;
        align-items: center;

        .nav-item {
            margin-right: 50px;
            cursor: pointer;

            .nav-link {
                border-bottom: 2px solid transparent;
                padding-bottom: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);

                &.active {
                    font-weight: 600;
                    color: rgba($accent-color, 0.87);
                    border-bottom: 2px solid $accent-color;
                }
            }
        }
    }

}

.active-scheme-outer {
    .white-bg {
        border-radius: 8px;
        height: calc(100vh - 241px);
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
            display: block;
            text-align: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            padding: 20px 0;
            color: $heading-color;
        }
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.overflow-hidden {
    overflow: hidden;
}



.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }

    &.add-incentive-popup {
        .modal-main {
            width: 410px;
        }
    }
}

.add-incentive-option {
    .custom-control {
        padding: 15px 0 0;

        .custom-control-label {
            span {
                display: inline-block;
                border-bottom: 1px solid rgba($heading-color, 0.1);
                width: calc(100% - 30px);
                padding-bottom: 15px;
            }
        }

        &:last-child {
            .custom-control-label {
                span {
                    border-bottom: 0px
                }
            }
        }
    }
}

[hidden] {
    display: none;
}

.accordion__button:before {
    position: absolute;
    content: '+';
    right: 20px;
    font-size: 30px;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    content: '-';
}

.accordion__button {

    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
    padding: 20px;
}


.add-incentive-accordion {
    margin-top: 20px;
    .accordion__item {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .accordion__button {
        background: #E3E7F1;
        border-radius: 8px 8px 0 0;
        padding: 10px 60px 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $heading-color;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }
    }

    .sub-accordion {
        .accordion__button {
            background: transparent;
            border-bottom: 1px solid $border-color;
            h2 {
                font-weight: normal;
                font-size: 16px;
            }
        }

        .accordion__item {
            border: 1px solid $border-color;
            border-radius: 8px;
        }
    }

    .right-action-item {
        display: flex;
        align-items: center;

        li {
            margin-left: 40px;

            i {
                font-size: 20px;
            }

            .ic-delete-icn {
                font-size: 28px;
            }
        }
    }
    .sub-accordion{
        .accordion__button{
            padding-right: 68px;
        }
    }
}


.ToggleSwitch {
    position: relative;
    // width: 5rem;
    // height: 2.35rem;
    // display: inline-block;

    &.ToggleSwitch__rounded {
        .Slider {
            border-radius: 15rem;
            background: rgba(157, 157, 157, 0.2);
            border: 1px solid rgba(157, 157, 157, 0.2);

            &:before {
                border-radius: 50%;
            }
        }
    }

    .ToggleSwitch__wrapper {
        position: relative;
        width: 46px;
        height: 26px;

        .Slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s ease;

            &:before {
                width: 21.33px;
                height: 21.33px;
                position: absolute;
                background: rgba($heading-color, 0.5);
                content: "";
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0.1rem;
                transform: translateY(-51%);
                transition: 0.4s;
                cursor: pointer;
                // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }

            &.isChecked {
                background: rgba($accent-color, 0.2);
                border-color: rgba($accent-color, 0.2);

                &:before {
                    left: calc(100% - 0.1rem - 1.4rem);
                    background: $accent-color;
                }
            }
        }
    }
}

.basic-detail-filter {
    display: flex;
    // align-items: center;

    fieldset {
        width: 225px;
        margin-right: 8px;
    }
}

.single-select {
    .react-select__value-container--has-value .react-select__placeholder {
        top: -19px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
        position:absolute;
    }

    .react-select__control--menu-is-open .react-select__placeholder {
        top: -19px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
        position:absolute;
    }

    .react-select__value-container {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }

    .css-26l3qy-menu {
        margin-top: 0px;
    }

    .material {
        border: 1px solid $border-color;
        border-radius: 8px;
    }

    .single-select .react-select__control,
    .single-select .react-select__control {
        border-color: transparent !important;
    }
}

.add-banner-btn-outer {
    display: flex;
    align-items: center;

    .browse-btn {
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        margin-left: 30px;

        span.label {
            position: relative;
            z-index: 0;
            background: #FFFFFF;
            border: 1px solid #8C8C8C;
            border-radius: 8px;
            height: 44px;
            line-height: 44px;
            font-weight: 500;
            font-size: 13px;
            color: $heading-color;
            cursor: pointer;
            min-width: 115px;
            display: block;
        }

        .upload-box {
            display: inline-block;
            position: absolute;
            // z-index: 1;
            width: 100%;
            height: 44px;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
        }

    }

    .add-banner-txt {
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: $heading-color;
        }

        span {
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: rgba($heading-color, 0.7);
        }
    }

    margin-bottom: 24px;
}

.description-card-outer {
    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        display: block;
        padding-bottom: 8px;
    }

    textarea {
        width: 80%;
        border-radius: 8px;
        margin-bottom: 24px;
        resize: none;
        padding: 15px;
    }
}

.incentive-login-msg {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    display: flex;
    align-items: center;
    margin-top: 25px;

    i {
        font-size: 20px;
        margin-right: 10px;
    }
}


.banner-preview-outer {
    width: 280px;
    height: 100%;

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
    }

    .banner-preview-bg {
        background: #FFFFFF;
        border: 1px solid #BBBBBB;
        border-radius: 8px;
        padding: 50px 0;
        height: calc(100% - 30px);
        max-height: 325px;
        cursor: pointer;
        text-align: center;

        .banner-previw-img {
            background: rgba(227, 231, 241, 0.45);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            height: 100%;
        }
    }
}

.left-right-panel-outer {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-top: 30px;

}

.area-drop-downfiled {
    width: 150px;
    margin-right: 10px;
}

.filed-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: $heading-color;
}

.form-filed {
    width: 160px;
    margin-right: 10px;

    fieldset {
        margin-bottom: 10px;
    }
}

.area-detail-filter {
    display: flex;
    justify-content: space-between;

}

.Platinum-gold-outer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 21px;

    .filed-group {
        display: flex;
        align-items: center;
        width: calc(49% - 20px);
        margin-left: 10px;
        margin-bottom: 10px;

        .form-input {
            width: 75px;
            margin-left: 12px;
        }

        .custom-control {
            display: flex;
            align-items: center;
        }
    }
}

.add-incenative-left-panel {
    width: calc(100% - 310px);
    margin-right: 30px;

    .area-detail-filter-outer {
        display: flex;
    }
}

.br-bottom {
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.app-submit-outer {
    margin-bottom: 30px;
  table {
        width: 60%;
  }
  
}

.add-incentive-table{
    table {
    
        th {
            text-align: left;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: $heading-color;
            width: 100px;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            color: rgba($heading-color, 0.8);
            padding: 10px 0;

            .form-input {
                width: 200px;
            }
        }
    }
}

.expire-soon-btn{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    background: #FF6868;
    border-radius: 5px;
    padding: 0px 10px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #FF6868;
    margin-right: 40px;
    cursor: pointer;
}

.app-booster-outer{
    margin-bottom: 30px;
    h3{
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        display: block;
        margin-bottom: 10px;
        color: $heading-color;
    }
    table {
        .add-minus{
            margin-left: 10px;
            cursor: pointer;
        }
        width: 60%;
        th {
            &:last-child{
                padding-left: 30px;
            }
        }

        td { 
            &:nth-child(4) {
                padding-left: 30px;
            }
        }
        .to-txt{
            font-weight: 400;
font-size: 13px;
line-height: 20px;
color: rgba($heading-color, 0.7);
padding: 0px 18px;
        }
    }
}

.basic-top-filter-outer{
    display: flex;
    align-items: center;
}

.extra-incentive-outer{
    margin-bottom: 40px;
    table{
        th{
            vertical-align: top;
            padding-bottom: 15px;
            span{
                font-weight: 400;
                font-size: 12px;
                line-height: 10px;
                color: rgba($heading-color, 0.5);
                display: block;
            }
        }
    }
}
.redeemable-point-outer{
    margin-bottom: 40px;
    table{
        width: 73%;
        th,td{
            padding: 0px 10px 10px 0px;
            &:first-child{
                width: 200px;
            }
        }
    }
    .single-select{
        width: 200px;
    }
}
.delete-incentive-popup{
     .modal-main {
        width: 400px;
    }
    
    
    .delete-popup-txt{
        display: block;
        text-align: center;
        padding-top: 40px;
        span{
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: $heading-color;
            display: block;
        }
        .yes-no-btn{
            button{
                margin: 20px 8px;
            }
            .btn-line{
                border-color: $accent-color;
                min-width: 125px;
            }
            .btn-primary{
                min-width: 125px;
            }
        }
    }
}

.loading {
    pointer-events: none;
}
.loading:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0,0%,100%,.54);
    z-index: 997;
}
.loading:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 50%;
    border: 8px solid rgba(0,0,0,.12);
    border-top-color: #e46536;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    z-index: 998;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.m-t-40{
    margin-top: 40px;
}

.h3{
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    display: block;
    padding-bottom: 8px;
}
.area-span{
    margin-bottom: -6px;
    // display: inherit;
}

.seller-opt .material.active-label .form-label {
    top: -23px!important;
}
.top21{
    top: -23px!important;
}

.date-label{
    top: -6px !important;
}
.top-date{
    top: -26px!important;
}
.redeemable-select-finacer-list {
    margin-bottom: 40px;
    .sub-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 20px;
        display: block;
    }
    table{
     width: 40%;   
    }
}

.payoutNote {
    color:black;
    margin-left:30px;
    span {
        color: red;
    }
}

.app-point-outer{
    .add-incenative-left-panel{
        width:100% !important
    }
    table {
       tr{
         display:flex;
            th{
                &:nth-child(1){
                    width: 15%;
                    padding-left:10px;
                }
                &:nth-child(2){
                    width: 5%;
                }
                &:nth-child(3),
                &:nth-child(4){
                  width: 25%; 
                  padding-left:50px;
                }
                &:nth-child(5),&:nth-child(6) {
                    width: 15%; 
                    padding-left:50px;
                }
                &:nth-child(6){
                    width: 20% !important;
                }
                
            }
            td{
                &:nth-child(1){
                    width: 15%;
                    padding-left:15px;
                }
                &:nth-child(2){
                    width: 5%;
                    padding-left:5px;
                } 
                &:nth-child(3),
                &:nth-child(4){
                  width: 26%;
                  padding-left:50px;
                }
                &:nth-child(5),&:nth-child(6) {
                    width: 15%; 
                    padding-left:50px;
                    .form-input {
                        width: 120px;
                    }
                }
                &:nth-child(6){
                    width: 20% !important;
                }

            }

       }
        
    }
}
   
.financier-dropdown{
    .reactCustomCheckbox{
        pointer-events: none;

    }
   
}
.point-config
{
.tree-dropdown .tree-container{
    border-radius: 8px;
    &:active{
        border-radius: 8px;
    }

}
.tree-dropdown .tree-container .divider-container{
    display: none;
}

.tree-dropdown .tree-container .dropdown-indicator{
    &:before{
    content: "\e917";
    position: absolute;
    right: 3px;
    color: #000;
    font-family: dc !important;
    font-size: 24px;
    top: 50%;
    transform: translateY(-50%);
    }
}
.tree-dropdown .react-tree-menu{
    background-color:#fff;
    border-radius: 4px;
    box-shadow:0 0 0 1px #fff, 0 4px 11px rgba(0,0,0,.1);
    width:260px;
    border:none
}
.tree-dropdown .tree-container.dropdown-active{
    border: 1px solid #cccccc;
}
.reactCustomCheckbox{
    padding-left: 25px !important;
    font-size:0.9rem !important;
}
.selected-option{
    background-color:#fff !important;
}
.selected-option-icon{
    display: none;
}
}

.financierspan{
    color: rgba(0, 0, 0, 0.50);
    font-size:13px
}

.tooltio-ic {
    cursor: pointer;
    margin-left: 5px;
    position: relative;
    top: 1px;
}
.area_am_dropdown{
    .css-1r4vtzz, .css-48ayfv 
    {
        width: 150px;
    }
}
.pd20{
    padding-right: 20px !important;
}

.amount-submit-outer {
    margin-bottom: 30px;
  table {
        width: 30%;
  }
  
}

.chart-table-card {
    box-shadow: 0px 2px 4px 0px #24272C0F;
    border: 1px solid #E0E0E0;
    padding: 20px;
    width: 100%;
    border-radius: 15px;

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 20px;
    }  
}

.csv-error-listing {
    background-color:  #FBE8E8; 
    padding: 20px;
    border: 1px;
    border-radius: 6px;

    ul{ 
        li{
            padding-bottom: 10px; 
            color: #E02020;

            &:first-child {
                color : $heading-color;
                font-size: 15px;
            }
         
        }
    }
}

.csv-download-txt {
    text-decoration: underline;
    color: $heading-color;
    padding-right: 8px;
}


.upload-csv-popup {
    .modal-main {
        width: 600px;
    }
}

.csv-success-msg-popup {
    .modal-main {
        display:block; 
        text-align : center;
        width: 500px; 
    }
}